import "./FormCircle.css";
const FormCircle = () => {
  return (
    <div className="FormCircle">
      <div className="circleUm"></div>
      <div className="circlePrincipal"></div>
      <div className="circleDois"></div>
    </div>
  )
}
export default FormCircle;