import { useEffect, useState } from 'react';
import api from "./services/api";
import './App.css';
/*COMPONENTS IMPORT*/
import SignUpButton from './components/SignUpButton';
import FormCircle from './components/FormCircle';
import Footer from './components/Footer';
import WhatsApp from './components/WhatsApp';
/*IMAGES IMPORT*/
import logoNormal from "./assets/ZinidTechHorizontal.png";
import inlustrationHome from "./assets/home-illustration.jpg";

function App() {
  const [serv, setServ] = useState([]);

  useEffect(() => {
    api
      .get("/services")
      .then((response) => setServ(response.data))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  return (
    <div className="App">
      <WhatsApp />
      <header className="App-header">
        <div className="menuLogo">
          <img src={logoNormal} className="imgLogo" alt="ZinidTech Logotipo. Um Z grande iniciando a palavra zinid seguido de tech" />
        </div>
        <ul className="menuList">
          <li><a href="https://faq.zinidtech.com.br" className="App-link">FAQ</a></li>
          <li><a href="https://blog.zinidtech.com.br" className="App-link">Blog</a></li>
          <li><SignUpButton /></li>
        </ul>
      </header>
      <div className="App-Container-home">
        <div className="col">
          <div className="textContainer">
            <h1>Onde a gente inova juntos. Talves podemos mudar o mundo.</h1>
            <p>Mudar para melhor</p>
            <SignUpButton />
          </div>
        </div>
        <div className="col">
          <FormCircle />
          <span className="imgCircle">
            <img src={inlustrationHome} className="img50" alt="ZinidTech Logotipo. Um Z grande iniciando a palavra zinid seguido de tech" />
          </span>
        </div>
      </div>
      <div className="App-Service-list">
        <ul>
          {serv?.map((s: any) => <li key={s.id}><span></span> {s.title}</li>)}
        </ul>
      </div>
      <Footer />
    </div>
  );
}
export default App;
