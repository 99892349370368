import { FaWhatsapp } from "react-icons/fa";
import "./WhatsApp.css";
const WhatsApp = () => {
  return (
    <div className="WhatsApp-Flutuante">
      <a href="https://wa.me/559870035296?text=Ol%C3%A1%2C+tenho+interesse+em+um+dos+servi%C3%A7os+da+ZinidTech.">
        <FaWhatsapp size="1.5em" />
        <p>Chame no WhatsApp</p>
      </a>
    </div>
  );
}
export default WhatsApp;