import { FaFacebook, FaTwitter, FaYoutube, FaLinkedin } from "react-icons/fa";
import "./Footer.css";
import logoBranca from "../assets/ZinidTechHorizontalBranca.png";
const Footer = () => {
  return(
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col50">
            <h6>Sobre Nós</h6>
            <p className="text-justify">Scanfcode.com <i>CODE WANTS TO BE SIMPLE </i> is an initiative  to help the upcoming programmers with the code. Scanfcode focuses on providing the most efficient code or snippets as the code wants to be simple. We will help programmers build up concepts in different programming languages that include C, C++, Java, HTML, CSS, Bootstrap, JavaScript, PHP, Android, SQL and Algorithm.</p>
          </div>
          <div className="col25">
            <h6>Serviços</h6>
            <ul className="footer-links">
              <li><a href="http://scanfcode.com/category/c-language/">C</a></li>
              <li><a href="http://scanfcode.com/category/front-end-development/">UI Design</a></li>
              <li><a href="http://scanfcode.com/category/back-end-development/">PHP</a></li>
              <li><a href="http://scanfcode.com/category/java-programming-language/">Java</a></li>
              <li><a href="http://scanfcode.com/category/android/">Android</a></li>
              <li><a href="http://scanfcode.com/category/templates/">Templates</a></li>
            </ul>
          </div>
          <div className="col25">
            <h6>Fale Conosco</h6>
            <ul className="footer-links">
              <li><a href="http://scanfcode.com/about/">Sobre Nós</a></li>
              <li><a href="http://scanfcode.com/contact/">Contact Us</a></li>
              <li><a href="http://scanfcode.com/contribute-at-scanfcode/">Contribute</a></li>
              <li><a href="http://scanfcode.com/privacy-policy/">Privacy Policy</a></li>
              <li><a href="http://scanfcode.com/sitemap/">Sitemap</a></li>
            </ul>
          </div>
        </div>
        <hr/>
      </div>
      <div className="container">
        <div className="row">
          <div className="col50">
            <p className="copyright-text">Copyright &copy; 2022 All Rights Reserved by <a href="./"><img src={logoBranca} className="imgFooter" alt="Logo da Zinid Tech." /></a>
            </p>
          </div>
          <div className="col50">
            <ul className="social-icons">
              <li><a className="facebook" href="./"><FaFacebook /></a></li>
              <li><a className="twitter" href="./"><FaTwitter /></a></li>
              <li><a className="youtube" href="./"><FaYoutube /></a></li>
              <li><a className="linkedin" href="./"><FaLinkedin /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;